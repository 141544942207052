import { Helmet } from "react-helmet";
import bannerimg from "../../../images/banners/timber.jpg";
import benefitsImage from "../../../images/Timber Loggin.jpg";
import demoImage from "../../../images/Logs.jpg";
import { IndustryPage } from "./industryPage";

// Detailed instructions for updating industry pages can be viewed in industryPage.js
export function Timber() {
  const bannerText =
    "Ironbark Timber Industry Software is a complete platform for clients in the Timber industry; from Logging, Milling (sawmill), " +
    "Fabrication and Wholesale through, to Retail, Export, EDI, Point of Sale, Truck Scheduling and Delivery.";
  const benefits = [
    "Track individual Pack Contents and produce customisable labels including pack labels",
    "Comprehensive date driven pricing structures available",
    "Sign on Glass truck solution with delivery scheduling",
    "RF / Barcode scanning options",
    "Integration with truss/ estimate packages",
    "Live stock with unlimited amount of products",
    "Complete financial and payroll integration",
    "multiple unit conversions per product.  Buy and sell in different units",
    "Fully integrated online webshop",
    "Ability to track and trace lengths",
    "Logging system",
    "Tablet programs available",
  ];
  const moduleDes1 = [
    "Debtors",
    "Creditors",
    "General ledger",
    "Business activity statements",
    "Payroll",
    "Asset management",
    "Budgets",
    "CRM",
  ];
  const moduleDes2 = [
    "Inventory management",
    "Quotes",
    "Purchasing",
    "Delivery scheduling",
    "POS",
    "BI reporting",
    "Imports and exports",
    "Customer orders",
  ];
  const moduleDes3 = [
    "Online sales websites",
    "Customer portals",
    "WMS - warehouse management",
    "Milling ",
    "Shipping registers",
    "EDI  for sales and purchasing",
    "Logging",
    "Production scheduling",
  ];
  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Timber, Sawmills, Milling And Production"
        />
        <meta name="robots" content="index,follow" />
        <meta
          name="keywords"
          content="timber, sawmills, production, logs, customer portals, webshop, web stores, WMS, Warehouse Management System, Warehouse Management Software, timber packs, Ironbark, Ironbark Software"
        />
      </Helmet>
      <IndustryPage
        bannerImage={bannerimg}
        pageHeader="Ironbark Timber"
        moduleHeader="Timber"
        bannerText={bannerText}
        benefits={benefits}
        benefitsImage={benefitsImage}
        m1Icon="fa-bar-chart"
        m1Title="Financials"
        m1Description={moduleDes1}
        m2Icon="fa-tree"
        m2Title="Operations"
        m2Description={moduleDes2}
        m3Icon="fa-calculator"
        m3Title="Other"
        m3Description={moduleDes3}
        demoImage={demoImage}
      />
    </div>
  );
}
