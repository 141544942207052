import { useState, useEffect } from "react";
import "../../css/pagination.css";
export function Pagination() {
  const [fullData, setFullData] = useState([]);
  const [visibleData, setVisisbleData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [redrawCallback, setRedrawCallback] = useState(() => {});
  useEffect(() => {
    onDataChange();
  }, [rowsPerPage, currentPage, fullData]);
  useEffect(() => {
    if (typeof redrawCallback == typeof (() => {})) {
      redrawCallback(visibleData, numPages);
    }
  }, [visibleData]);
  useEffect(() => {
    if (typeof redrawCallback == typeof (() => {})) {
      redrawCallback(visibleData, numPages);
    }
  }, [numPages]);
  function GetCurrentPage() {
    return currentPage;
  }
  function GetRowsPerPage() {
    return rowsPerPage;
  }
  function SetVisibleRows(val) {
    if (typeof parseInt(val) == typeof 0) setRowsPerPage(val);
    else console.log(`invalid number '${val}'. Cannot set visible rows`);
  }
  function SetPage(pageNum) {
    let val = parseInt(pageNum);
    if (typeof val == typeof 0) {
      setCurrentPage(val);
    } else
      console.log(`Error: Invalid number '${pageNum}'. Cannot set page number`);
  }
  function NextPage() {
    setCurrentPage(currentPage + 1);
  }
  function PrevPage() {
    setCurrentPage(currentPage - 1);
  }
  function GetPage() {
    return currentPage;
  }
  function SetData(data) {
    if (!Array.isArray(data))
      console.log("Error: Data must be provided as an array");
    else {
      setFullData(data);
    }
  }
  function GetFullData() {
    return fullData;
  }
  function GetVisibleData() {
    return visibleData;
  }
  function GetNumPages() {
    return numPages;
  }
  function onDataChange() {
    let data = [];
    if (!Array.isArray(fullData)) {
      console.log("Error updating pagination data. Full data was not an array");
    }
    let pages = Math.ceil(fullData.length / rowsPerPage);
    if (pages > -1) setNumPages(pages);
    for (
      let i = rowsPerPage * currentPage;
      i < rowsPerPage * (currentPage + 1) && i < fullData.length;
      i++
    ) {
      data.push(fullData[i]);
    }
    setVisisbleData(data);
  }
  function SetRedrawCallback(func) {
    if (typeof func == typeof (() => {})) {
      setRedrawCallback(() => func);
      if (visibleData.length > 0) func(visibleData, numPages);
    }
  }

  return {
    GetCurrentPage,
    GetRowsPerPage,
    SetVisibleRows,
    SetPage,
    NextPage,
    PrevPage,
    GetPage,
    GetNumPages,
    SetData,
    GetFullData,
    GetVisibleData,
    SetRedrawCallback,
  };
}
export function PaginationWidget(props) {
  const [pages, setPages] = useState(0);
  const [pageTiles, setPageTiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  useEffect(() => {
    setPages(props.numPages);
    UpdateTiles(props.numPages);
  }, [props.numPages]);
  useEffect(() => props?.onNavigate(currentPage), [currentPage]);
  function UpdateTiles(numPages) {
    let t = [];
    for (let i = 0; i < numPages; i++) {
      t.push(i);
    }
    setPageTiles(t);
  }
  function NextPage() {
    if (currentPage < pages - 1) setCurrentPage(currentPage + 1);
  }
  function PrevPage() {
    if (currentPage > 0) setCurrentPage(currentPage - 1);
  }
  function SetPage(e) {
    let p = e?.target?.id ?? -1;
    p = parseInt(p);
    if (p > -1) {
      setCurrentPage(p);
    }
  }
  function CheckCutoff() {
    //Offset values represent additional spaces past the crop off point that can be displayed
    let leftOffset = 0;
    let rightOffset = 0;
    if (currentPage < props.cropAt) rightOffset = props.cropAt - currentPage;
    if (pageTiles.length - currentPage < props.cropAt)
      leftOffset = props.cropAt - (pageTiles.length - currentPage);

    if (leftOffset < 0) leftOffset = 0;
    if (rightOffset < 0) rightOffset = 0;
    leftOffset += props.cropAt;
    rightOffset += props.cropAt;
    return [leftOffset, rightOffset];
  }
  return (
    <div className="pagination-widget">
      <div
        onClick={PrevPage}
        className={currentPage > 0 ? "" : "disabled"}
      >{`<`}</div>
      {currentPage > props.cropAt ? (
        <div onClick={SetPage} id={0}>
          ...
        </div>
      ) : (
        <></>
      )}
      {pageTiles.map((x, index) => (
        <div
          id={index}
          key={index}
          onClick={SetPage}
          className={
            index == currentPage
              ? "active"
              : index < currentPage - CheckCutoff()[0] ||
                index > currentPage + CheckCutoff()[1]
              ? // : index < currentPage - props.cropAt ||
                //   index > currentPage + props.cropAt
                "hidden"
              : ""
          }
        >
          {index + 1}
        </div>
      ))}
      {currentPage < pageTiles.length - props.cropAt ? (
        <div onClick={SetPage} id={pageTiles.length - 1}>
          ...
        </div>
      ) : (
        <></>
      )}
      <div
        onClick={NextPage}
        className={currentPage < pages - 1 ? "" : "disabled"}
      >{`>`}</div>
    </div>
  );
}
