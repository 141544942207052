import { Link } from "react-router-dom";
import "../../css/global.css";
export function IBButton(props) {
  if (props.link.indexOf("http") > -1) {
    return (
      <a
        className={props.class + " ib-button"}
        target={props.target}
        href={props.link}
      >
        {props.label}
      </a>
    );
  } else {
    return (
      <Link
        target={props.target}
        className={props.class + " ib-button"}
        to={props.link}
      >
        {props.label}
      </Link>
    );
  }
}
