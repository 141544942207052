import "../../css/portfolio.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import { UAParser } from "ua-parser-js";
export function Portfolio(props) {
  const style = { backgroundImage: `url(${props.image})` };
  const [clickable, setClickable] = useState(false); //Only interacted with on mobile devices
  const deviceInfo = new UAParser();
  function OnClick(e) {
    let device = deviceInfo.getDevice().model;
    if (device != undefined) {
      //Testing shows desktop environments are undefined
      if (clickable == false) {
        e.preventDefault();
        setClickable(true);
      }
    }
  }
  function DisableClick() {
    setClickable(false);
  }
  return (
    <Link to={props.link} onClick={OnClick} onMouseOut={DisableClick}>
      <div style={style} className="portfolio-tile"></div>
      <div className="portfolio-tile tile-hover">{props.text}</div>
    </Link>
  );
}
