import { Banner } from "../../util/banner";
import { Solution } from "../../util/solution";
import { RequestDemo } from "./requestDemo";
import "../../../css/industry.css";
import { CustomElement } from "../../util/customElement";
import { IBButton } from "../../util/ibButton";

//  WARNING: MODIFYING THIS PAGE WILL APPLY CHANGES TO ALL INDUSTRY PAGES
//  Each industry page has been created with the understanding that each will contain each of the following items:
//    bannerImage     - This is the image that the banner will use. Make sure to import this at the top of the industry page
//                       using the following format: import bannerimg from "../../../images/fullsize/[filename.jpg]";
//    pageHeader      - This is the main header text that will appear on the banner
//    bannerText      - This is the smaller text used to describe the page that appears on the banner below the title
//    moduleHeader    - This is the text used in the header of the Modules section of the page. It should be supplied as below:
//                      moduleHeader="Fresh Produce"      *Note: This will display as "Fresh Produce Models"
//    benefits        - This should be an array of items to be displayed as a list in the "Benefits" section of the industry pages
//    benefitsImage   - This is the image that will be displayed to the left of the benefits list.
//                      *Note: Import this file in the same manner as the bannerImage
//    m1Icon          - The icon used for module tile
//    m1Title         - The title that is displayed immediately below the icon
//    m1Description   - If provided as an array, this will render as a dotpoint list. Otherwise it will render as a block of text
//    m2Icon          - See m1Icon
//    m2Title         - See m1Title
//    m2Description   - See m1Description
//    m3Icon          - See m1Icon
//    m3Title         - See m1Title
//    m3Description   - See m1Description
//    demoImage       - This is the background image for the "REQUEST A DEMO" section at the bottom of industry pages
//    element         - *OPTIONAL*  Custom HTML content that is inserted between the benefits and demo sections

export function IndustryPage(props) {
  return (
    <div>
      <Banner
        img={props.bannerImage}
        // title={props.pageHeader}
        // subtitle={props.bannerText}
      />
      <div className="page-content industry-page">
        <h1 className="page-header">{props.pageHeader}</h1>
        <h2 className="page-subheading">{props.bannerText}</h2>

        <h1 className="solution-container-header">
          {props.moduleHeader} Modules
        </h1>
        <div className="solution-container">
          <Solution
            icon={props.m1Icon}
            title={props.m1Title}
            description={props.m1Description}
          />
          <Solution
            icon={props.m2Icon}
            title={props.m2Title}
            description={props.m2Description}
          />
          <Solution
            icon={props.m3Icon}
            title={props.m3Title}
            description={props.m3Description}
          />
        </div>
        <IBButton label="Enquire Here" link="/contact" />
      </div>
      <div className="benefits-container">
        <div className="page-content">
          <h1>Benefits</h1>
          <div>
            <img alt="" src={props.benefitsImage} />
            <ul>
              {props.benefits.map((benefit, index) => (
                <li key={index}>{benefit}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <CustomElement element={props.element}></CustomElement>
      <RequestDemo img={props.demoImage} />
    </div>
  );
}
