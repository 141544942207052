import { Helmet } from "react-helmet";
import { Service } from "../util/service";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import customDevImage from "../../images/desk.jpg";
import consultingImage from "../../images/CONSULTING.jpg";
import webDesMobileAppImage from "../../images/WEBDESIGN.jpg";
import bIReportingImage from "../../images/dashboard-great-design-for-any-site-purposes-business-infographic-vector-id1156740048.jpg";
import thirdPartyImage from "../../images/THIRDPARTYINTEGRATIONS.jpg";
import automationImage from "../../images/AUTOMATION.jpg";
import hostingServicesImage from "../../images/HOSTING.jpg";
import implementationImage from "../../images/IMPLEMENTATIONS.jpg";
import supportImage from "../../images/SUPPORT.jpg";
import { Banner } from "../util/banner";
import bannerImage from "../../images/banners/services.jpg";
import "../../css/services.css";

export function Services(props) {
  //  Each service in the services array should include:
  //    hash        - This represents an Id on the service tile which is used to automatically scroll to the appropriate section when the user navigates via the navbar
  //                  *Note:    This should be the same as the respective route setup in navBar.js
  //                  *Example: a hash value of "implementation" will be displayed when navigating to /services#implementation
  //    image       - This is the image for the service. It should be imported at the top of this page before being used below
  //    title       - This is the title that appears on each service tile
  //    description - This is the text that displays below each service tile
  const services = [
    {
      hash: "development",
      image: customDevImage,
      title: "Custom Development",
      description:
        "At Ironbark we understand that one size does not fit all businesses and that processes need to be designed to suit the way you work. With Ironbark's versatile technology and broad implementation experience we can easily discuss, create, and optimise technology to support individual requirements. Ironbark works closely with our clients to produce bespoke software that will give our clients an edge over their competition.",
    },
    {
      hash: "consulting",
      image: consultingImage,
      title: "Consulting",
      description:
        "Ironbark offers consulting services to help you solve your business problems through smarter processes and applications. We can come to you to assess your processes and assist you in streamlining your business requirements and help you stay ahead of emerging technology. We can help you gain insights through better information / data management, process workflows and database reporting.",
    },
    {
      hash: "webdesign",
      image: webDesMobileAppImage,
      title: "Web Design and Mobile Applications",
      description:
        "Ironbark offers all types of applications including web based, tablet and mobile RF programs so no matter where you are working from, we have a solution that fits. Ironbark will develop web applications including custom e-commerce sites to help your business grow. RF solutions in your warehouse will allow for a paperless solution. Tablets in trucks and on sales floor offer more mobility for staff.",
    },
    {
      hash: "reporting",
      image: bIReportingImage,
      title: "Business Intelligence Reporting",
      description:
        "Ironbark allows for ad hoc reporting using Power BI so that you, the user, can prepare and analyse your business metrics and trends on the fly. Ironbark can also help you create custom reports to give you actionable data insights about your business.",
    },
    {
      hash: "integration",
      image: thirdPartyImage,
      title: "Third Party Integrations",
      description:
        "Ironbark supports integrations with a variety of products to give our customers greater functionality and to streamline your workflows. Whether it be a supermarket chain EDI or supplier price list loads, Ironbark will work with your business to produce a great outcome.",
    },
    {
      hash: "automation",
      image: automationImage,
      title: "Automation",
      description:
        "Ironbark wants to help you work better not harder, so wherever possible we can aid in configuring automatic tasks to make your job easier. From loading of orders via a website, emailing of invoices, loading of fuel imports, or running specific reports, Ironbark can customize the processes to simplify your day.",
    },
    {
      hash: "hosting",
      image: hostingServicesImage,
      title: "Hosting Services",
      description:
        "The Ironbark hosted solution provides a scalable, resilient, and maintenance-free infrastructure and platform managed solutions for your business needs. Ironbark utilise Amazon Web Services (AWS) Cloud Infrastructure in Australia to deliver hosted servers. AWS provides a highly reliable, scalable, infrastructure platform in the cloud that powers hundreds of thousands of businesses in 190 countries around the world. This allows Ironbark to focus on delivering the most trusted, reliable, and resilient service available, while bringing infrastructure online quickly and efficiently. Ironbark offers 24/7 support where needed to all hosted clients as well as secure backup services for peace of mind.",
    },
    {
      hash: "implementation",
      image: implementationImage,
      title: "Implementation",
      description:
        "Ironbark project-manage the entire transition between systems including clarifying system requirements, configuring the system, data conversion where applicable, and user training. Selecting and implementing a total IT solution to manage an entire business is a major enterprise. It often requires significant organisational change and reengineering of business processes. In larger or more complex environments, a change-agent or management consultant may be required. Ironbark maintains a positive working relationship with both internal and external consultants assisting in easing the transition between software systems and have a network of suitable contacts for clients in need of recommended services.",
    },
    {
      hash: "support",
      image: supportImage,
      title: "Support",
      description:
        "At Ironbark we understand that supporting our clients is critical to ensure their businesses run smoothly. We run a dedicated help desk to provide technical assistance and troubleshooting to all clients. Senior programmers are available for custom development of systems, and further training sessions are easily arranged to suit. The Ironbark engage portal allows tickets to be logged and tracked by clients. This is actively monitored by our support team to ensure quick resolutions. Ironbark offers 24/7 support where needed. Ensuring clients reap the maximum value from their IT solutions is paramount; Ironbark are proud to deliver software that evolves with clients' business needs.",
    },
  ];
  const location = useLocation();
  useEffect(() => {
    if (location.hash !== undefined && location.hash.length > 0)
      window.location.href = location.hash;
  }, [location.hash]);

  return (
    <div>
      <Helmet>
        <meta name="description" content="Ironbark Services " />
        <meta name="robots" content="index,follow" />
        <meta
          name="keywords"
          content="Ironbark, Ironbark Software, ERP, EDI, Custom Software, Bespoke Software, Hosting, Implementation. Support, BI, BI Reporting, Web Apps, Web Design, Consulting, Mobile Apps, RF Scanning, WMS, "
        />
      </Helmet>
      {/* <div
        className="service-main-img"
        style={{ backgroundImage: `url(${bannerImage})` }}
      ></div> */}
      <Banner
        img={bannerImage}
        // title="Services"
        // subtitle="Ironbark offers a range of services to support all your business
        //   needs."
      />
      <div className="page-content">
        <h1 className="page-header">Services</h1>
        <h2 className="page-subheading">
          Ironbark offers a range of services to support all your business
          needs.
        </h2>
        <div className="services-container">
          {services.map((s, index) => {
            return (
              <Service
                key={index}
                hash={s.hash}
                image={s.image}
                title={s.title}
                description={s.description}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
