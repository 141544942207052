import { Route, Routes, BrowserRouter } from "react-router-dom";
import { React } from "react";
import { NavBar } from "./components/util/navBar";
import { Home } from "./components/pages/home";
import { FreshProduce } from "./components/pages/industries/freshProduce";
import { Timber } from "./components/pages/industries/timber";
import { FuelDistribution } from "./components/pages/industries/fuelDistribution";
import { ThirdPartyLogistics } from "./components/pages/industries/thirdPartyLogistics";
import { WarehouseManagement } from "./components/pages/industries/warehouseManagement";
import { Manufacturing } from "./components/pages/industries/manufacturing";
import { Retail } from "./components/pages/industries/retail";
import { ProjectManagement } from "./components/pages/industries/projectManagement";
import { Features } from "./components/pages/features";
import { News } from "./components/pages/news/news";
import { Contact } from "./components/pages/contact";
import { Footer } from "./components/util/footer";
import { Services } from "./components/pages/services";
import { Modules } from "./components/pages/modules";
import { ScrollToTop } from "./components/util/scrollToTop";
import { NewsArticle } from "./components/pages/news/newsArticle";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import logo from "./images/logo.png";
import "./fonts/font-awesome.min.css";
import "./fonts/fontawesome-all.min.css";
import "./css/global.css";
import "./css/animation.css";
import "bootstrap/dist/css/bootstrap.min.css";

//  This is the master container that the rest of the site is rendered through. Anything that is displayed on every page should be added here
//  This page also includes all the navigation routes accessible throughout the site
//  To declare a new navigation path, create a new Route element between the Routes container
//  New Routes should contain:
//    path    - Each path should be relative to the root directory, starting with a "/"
//    element - This is the react component that is rendered when on a given page. Each of these should be imported at the top of this page
function App() {
  return (
    <div id="App" className="App">
      <BrowserRouter>
        <ScrollToTop />
        <NavBar />
        <div className="content">
          <Routes>
            <Route index path="/" element={<Home />} />
            <Route path="/industries/freshproduce" element={<FreshProduce />} />
            <Route path="/industries/timber" element={<Timber />} />
            <Route
              path="/industries/fueldistribution"
              element={<FuelDistribution />}
            />
            <Route
              path="/industries/thirdpartylogistics"
              element={<ThirdPartyLogistics />}
            />
            <Route
              path="/industries/warehousemanagement"
              element={<WarehouseManagement />}
            />
            <Route
              path="/industries/manufacturing"
              element={<Manufacturing />}
            />
            <Route path="/industries/retail" element={<Retail />} />
            <Route
              path="/industries/projectmanagement"
              element={<ProjectManagement />}
            />
            <Route path="/services" element={<Services />} />
            <Route path="/features" element={<Features />} />
            <Route path="/modules" element={<Modules />} />
            <Route path="/news" element={<News />} />
            <Route path="/news/:Id" element={<NewsArticle props />} />
            <Route
              path="/contact"
              element={
                <GoogleReCaptchaProvider reCaptchaKey="6Ld_pMUfAAAAADGJh2bvFLT3bxEUTFD4W9dzaFrt">
                  <Contact />
                </GoogleReCaptchaProvider>
              }
            />
            <Route element={<Home />} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
