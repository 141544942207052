import { Helmet } from "react-helmet";
import { Solution } from "../util/solution";
import { Banner } from "../util/banner";
import bannerImage from "../../images/banners/modules.jpg";
import "../../css/modules.css";
import { IBButton } from "../util/ibButton";

export function Modules() {
  //#region module declarations
  //  For the purpose of readability, each module on this page has been separated into an individual object. After being declared, these should be added to the modules array.
  //  When updating/adding modules they should have the following properties:
  //    img         - This is the icon displayed at the top of the module tile. It should be selected using fontawesome classes
  //    title       - This is the title of the module, it will display immediately below the image
  //    Desciption  - This is the text that will display at the bottom of the module tile.
  //                  *Note: Passing the description as an array will cause the items to display as a dot point list
  const financials = {
    img: "fas fa-balance-scale",
    title: "Financials",
    description: [
      "Payroll",
      "Ledger",
      "General Ledger",
      "Debtors",
      "Asset Register",
      "Creditors",
      "Stock",
      "Bank Reconcilliation",
      "Management",
    ],
  };
  const operations = {
    img: "fa fa-truck",
    title: "Operations",
    description: [
      "Warehouse Management System",
      "BI Reporting",
      "Import / Export",
      "Forecasting",
      "Sales",
      "Procurement",
      "Sales Analysis",
      "OCR",
      "Quotes",
    ],
  };
  const freshFood = {
    img: "fas fa-apple-alt",
    title: "Fresh Food",
    description: [
      "Sales Floor",
      "Value Add",
      "Store Orders",
      "Crates and Pallets",
      "Freight Reconciliation",
      "Web QA",
      "Packhouse",
      "Grower Paybacks",
      "Third Party Services",
    ],
  };
  const timber = {
    img: "fa fa-tree",
    title: "Timber",
    description: [
      "Logging",
      "Milling",
      "Shipping Register",
      "Delivery Scheduling",
      "Production Scheduling",
      "Quotes",
      "Web Stores",
      "End Sections",
      "Treatments",
    ],
  };
  const fuel = {
    img: "fas fa-gas-pump",
    title: "Fuel",
    description: [
      "Pricing",
      "Cyclic Billing",
      "Truck Computers",
      "Truck Scheduling",
      "OPT Imports",
      "Fuel Cards",
      "Automation Dashboards",
      "Direct Debit - DDR",
    ],
  };
  const edi = {
    img: "fa fa-download",
    title: "EDI",
    description: [
      "Coles EDI",
      "Woolworths EDI",
      "Costco EDI",
      "Bunnings EDI",
      "Aldi EDI",
      "Mitre 10 EDI",
      "Supplier EDI Options",
    ],
  };
  const jobCosting = {
    img: "far fa-calendar-alt",
    title: "Jobcosting",
    description: [
      "Equipment Register",
      "Bill of Materials",
      "Job And Activity Costings",
      "Online Web Timesheets",
      "Production Manangement",
    ],
  };
  const logistics = {
    img: "fas fa-truck-loading",
    title: "3PL",
    description: [
      "3PL Cold Storage",
      "Pallet and Crate Control",
      "RF Scanning applications",
      "Label Designer",
      "EDI inwards and outwards",
    ],
  };
  const retail = {
    img: "fa fa-shopping-cart",
    title: "Retail",
    description: [
      "POS",
      "Sales Analysis",
      "Total Apparel Management",
      "CRM",
      "Customer Web Portals",
      "Secure payment methods",
    ],
  };
  //#endregion
  //  Each module declared above should be added in to this array to display correctly when the page renders
  const modules = [
    financials,
    operations,
    freshFood,
    timber,
    fuel,
    edi,
    jobCosting,
    logistics,
    retail,
  ];
  return (
    <div>
      <Helmet>
        <meta name="description" content="Ironbark Modules" />
        <meta name="robots" content="index,follow" />
        <meta
          name="keywords"
          content="Ironbark, Ironbark Software, ERP, Enterprise Resource Planning Software, WMS, CRM , Timber , Fresh Food, Prodution, Jobcosting, Fuel , Fuel Distribution, Packhouse, Retail, Payroll, General Ledger, Assets, Account Receivable, Account Payable, Stock, Stock Control, Automation, Processes"
        />
      </Helmet>
      <Banner
        img={bannerImage}
        // title="Modules"
        // subtitle="Ironbark is made up of a selection of modules that interact with each
        //   other."
      />
      <div className="page-content modules-page">
        <h1 className="page-header">Modules</h1>
        <h2 className="page-subheading">
          Ironbark is made up of a selection of modules that interact with each
          other.
        </h2>
        <div className="solution-container">
          {modules.map((m, index) => (
            <Solution
              key={index + m.title}
              link=""
              icon={m.img}
              title={m.title}
              description={m.description}
            />
          ))}
        </div>
      </div>
      <div className="page-content">
        <IBButton label="Enquire Here" link="/contact" />
      </div>
    </div>
  );
}
