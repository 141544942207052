import { Helmet } from "react-helmet";
import bannerimg from "../../../images/banners/warehousing.jpg";
import benefitsImage from "../../../images/Racking-Pic-for-Racking-Pag.jpg";
import { IndustryPage } from "./industryPage";
import demoImage from "../../../images/WarehouseNew2.jpg";

// Detailed instructions for updating industry pages can be viewed in industryPage.js
export function WarehouseManagement() {
  const bannerText =
    "Improve your inventory control, manage supply chain fulfillment operations from start to " +
    "finish with our completely integrated Warehouse Managment Solution.";
  const benefits = [
    "A comprehensive web based warehouse management solution",
    "Complete EDI integration",
    "Integrate with Xero",
    "Track and trace various crates and pallets",
    "Empower clients to see their own stock holdings",
    "Live stock control and bin movement tracking",
    "Complete financial and payroll integration",
    "Label designer - create multiple layouts",
    "Comprehensive charge options",
    "Customer based date driven charge structures ",
    "Manage stock issues such as quarantines ",
    "Sales analysis and BI reporting",
  ];
  const moduleDes1 = [
    "Stock put aways",
    "Staging",
    "Pallet tracking",
    "Stock takes",
    "Picking",
    "Stock look ups",
    "Sales entry",
  ];
  const moduleDes2 = [
    "Real time inventory management",
    "Job queues",
    "Multiple locations",
    "SSCC labels",
    "Barcode scanning and creation",
    "Picker times",
    "Bin groups and locations",
    "Picking automation and batch picking",
    "Automated emailing",
    "Multiple warehouse tracking",
    "Pallet tracking",
  ];
  const moduleDes3 = [
    "BI reporting",
    "Full financials",
    "Complete payroll system",
    "QA systems",
    "EDI integrations",
    "Sales",
    "Purchasing",
    "PDA options",
    "Freight tracking",
  ];
  return (
    <div>
      <Helmet>
        <meta name="description" content="Warehouse Management, WMS" />
        <meta name="robots" content="index,follow" />
        <meta
          name="keywords"
          content=" ERP, Enterprise Resource Planning Software, Supply Chain, WMS, Warehouse Management System, Warehouse Management Software, RF Scanning, EDI, Ironbark, Ironbark Software"
        />
      </Helmet>
      <IndustryPage
        bannerImage={bannerimg}
        pageHeader="Warehousing"
        moduleHeader="Warehouse"
        benefits={benefits}
        benefitsImage={benefitsImage}
        bannerText={bannerText}
        m1Icon="fa-shopping-basket"
        m1Title="Web Apps"
        m1Description={moduleDes1}
        m2Icon="fas fa-dice-d6"
        m2Title="Warehousing"
        m2Description={moduleDes2}
        m3Icon="fa-calendar"
        m3Title="Other Modules"
        m3Description={moduleDes3}
        demoImage={demoImage}
      />
    </div>
  );
}
