import { Solution } from "./solution";
import "../../css/footer.css";
export function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer">
      <h1>Contact Us</h1>
      <p>
        Ready to start your next project with us? Give us a call, drop in, email
        or fill in the contact form and we will call you.
      </p>
      <div className="solution-container">
        <Solution
          link="tel:+61734233811"
          icon="fa-phone"
          title=""
          static
          description="1800 649 524  or +61 7 3423 3811"
        />
        <Solution
          static
          icon="fa-address-book"
          title=""
          description="Level 1, 29 Brandl Street, Brisbane Technology Park , Eight Mile Plains  Qld"
        />
        <Solution
          link="mailto:sales@ironbark.com.au"
          icon="fa-envelope"
          title=""
          static
          description="sales@ironbark.com.au"
        />
      </div>
      <p>Ironbark Software © {currentYear}</p>
    </div>
  );
}
