import { IBButton } from "../../util/ibButton";
export function RequestDemo(props) {
  return (
    <div
      className="request-demo"
      style={{
        backgroundImage: `url(${props.img})`,
        backgroundPosition: "center",
        justifyContent: "center",
      }}
    >
      <IBButton label="REQUEST A DEMO" link="/contact" />
    </div>
  );
}
