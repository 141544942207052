//This component represents previews of news items
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { IBButton } from "../../util/ibButton";
export function NewsPreview(props) {
  const [preview, setPreview] = useState("");
  const [id, setId] = useState("");

  function ExtractContent(s) {
    let element = document.createElement("span");
    element.innerHTML = s;
    return element.textContent || element.innerText;
  }

  useEffect(() => {
    let content = ExtractContent(props.Body);
    setPreview(content);
    setId(props.Id);
  }, [props.Body]);

  return (
    <Link to={`/news/${id}`}>
      <div className="news-item news-preview">
        <h1>{props.Title}</h1>
        <p className="news-credit">
          <span className="credit">
            <i className="fa fa-calendar" />
            {props.Created}
          </span>
          <span className="credit">
            <i className="fa fa-user" />
            {props.Author}
          </span>
          <span className="credit">
            <i className="fa fa-th-list" />
            {props?.Categories?.map((category, index) => (
              <span key={index} className="category">
                {category}
              </span>
            ))}
          </span>
        </p>
        <p className="text-preview">{preview}</p>
        <p>
          <span className="news-tags">
            Tags:{" "}
            {props?.Tags?.map((tag, index) => (
              <span key={index} className="tag">
                {tag}
              </span>
            ))}
          </span>
        </p>
        <IBButton
          className="ironbark-green"
          label="Read More"
          link={`/news/${id}`}
        />
      </div>
    </Link>
  );
}
