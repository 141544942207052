import { Helmet } from "react-helmet";
import bannerimg from "../../../images/banners/projectmanagement.jpg";
import benefitsImage from "../../../images/keyboard.jpg";
import demoImage from "../../../images/image1.jpg";
import { IndustryPage } from "./industryPage";

// Detailed instructions for updating industry pages can be viewed in industryPage.js
export function ProjectManagement() {
  const bannerText = "Gain financial control over your work in progress.";
  const benefits = [
    "Estimate percentage complete on jobs",
    "Accurate profit calculations",
    "Authorisation purchasing work flow",
    "Service type invoicing ",
    "Provision for recovered costs",
    "Material requirement planning ",
    "Provide tenders and quotes",
    "Schedule your jobs ",
    "Production and BI reporting",
    "Simple progress invoicing ",
    "Attach jobs, sales, quotes, labour, and materials together",
    "Project manage your jobs",
  ];
  const moduleDes1 = [
    "Production reporting",
    "Detailed and summary job costing reports",
    "Job material requirement reporting",
    "Labour summary by employee, hours and jobs",
    "Committed order reporting",
    "Variance to budget reporting",
    "Job timesheets",
    "Completed job reports",
    "Contract summaries",
    "Multiple sub reporting levels available throughout all reports",
  ];
  const moduleDes2 = [
    "Accommodates sub jobs or jobs within jobs",
    "Works orders",
    "Labour tracking by job",
    "Committed orders and direct to site",
    "Track contractors timesheets",
    "Contract variances",
    "Work in progress against individual jobs",
    "Job budgets vs actuals",
    "Automated emailing",
    "Equipment registers against jobs",
    "Sub grouping of transactions",
    "Various job close  / take up profit methodologies",
  ];
  const moduleDes3 = [
    "BI reporting",
    "Full financials",
    "Full payroll system",
    "QA systems",
    "EDI integrations",
    "Sales",
    "Purchasing",
    "Production scheduling",
    "Assets",
    "OCR processing",
    "Web online timesheets",
  ];
  return (
    <div>
      <Helmet>
        <meta name="description" content="Job costing Project Management" />
        <meta name="robots" content="index,follow" />
        <meta
          name="keyworks"
          content="job costing, projects, production, scheduling, works orders, WMS, Warehouse Management System, Warehouse Management Software, Jobs"
        />
      </Helmet>
      <IndustryPage
        bannerImage={bannerimg}
        pageHeader="Project Management"
        moduleHeader="Job Costing"
        benefits={benefits}
        benefitsImage={benefitsImage}
        bannerText={bannerText}
        m1Icon="fa-headphones-alt"
        m1Title="Job Reporting"
        m1Description={moduleDes1}
        m2Icon="fa-line-chart"
        m2Title="Features"
        m2Description={moduleDes2}
        m3Icon="fa-keyboard-o"
        m3Title="Other Modules"
        m3Description={moduleDes3}
        demoImage={demoImage}
      />
    </div>
  );
}
