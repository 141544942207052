import { Helmet } from "react-helmet";
import bannerimg from "../../../images/banners/fuel.jpg";
import benefitsImage from "../../../images/iStock-1199749863.jpg";
import demoImage from "../../../images/FuelTanks.jpg";
import { IndustryPage } from "./industryPage";

// Detailed instructions for updating industry pages can be viewed in industryPage.js
export function FuelDistribution() {
  const bannerText =
    "The Ironbark fuel distribution system has been specifically designed for clients in the petroleum industry. " +
    "It is an integrated system for back office management , truck computers and scheduling providing the infrastructure and support " +
    "to effectively manage your fuel operation.";
  const benefits = [
    "Ampol integration with myDelivery",
    "POS integrations and automations",
    "Consolidated site reporting",
    "Web portals for sites , customers and staff",
    "Fuel card transaction tracking",
    "Stock control with unlimited amount of products",
    "Complete financial and payroll integration",
    "Secure payment gateways",
    "Project / job tracking",
    "Customer based pricing structures ",
    "Terminal gate pricing and load docket tracking",
    "Sales analysis and BI reporting",
  ];
  const moduleDes1 = [
    "Android truck app",
    "Drop offs and pick ups",
    "Sale deliveries",
    "POD sign off",
    "Terminal pick ups",
    "Schedule deliveries",
    "GPS tracking",
    "Multiple runs per day and truck",
    "Capture timesheets",
    "Integrates with Ironbark ERP via API's",
  ];
  const moduleDes2 = [
    "Real time inventory management",
    "Multi location / multi BAS",
    "Full financials and payroll",
    "Warehouse managment",
    "Daily dips and transfers",
    "BI Reporting",
    "Direct debit request - DDR",
    "Commission agent management",
    "Web based delivery scheduling",
    "Mutiple pricing modules available",
    "Cyclic billing",
  ];
  const moduleDes3 = [
    "Sales website",
    "Customer web portals",
    "Customer fuel cards",
    "Integration with third party Interfaces from various POS systems",
    "Store L15 and ambient",
    "Detailed fuel statements",
    "Automation dashbaord",
    "OPT imports",
    "Load docket tracking",
  ];
  return (
    <div>
      <Helmet>
        <meta name="robot" content=".index,follow" />
        <meta name="description" content="Fuel Distribution truck computers" />
        <meta
          name="keywords"
          content="fuel, trucks , truck computers, android, delivery scheduling,, commission agent, automation, Ironbark Software , Ironbark, ERP"
        />
      </Helmet>
      <IndustryPage
        bannerImage={bannerimg}
        pageHeader="Fuel Distribution"
        moduleHeader="Fuel"
        bannerText={bannerText}
        benefits={benefits}
        benefitsImage={benefitsImage}
        m1Icon="fa-truck"
        m1Title="Truck Computers"
        m1Description={moduleDes1}
        m2Icon="fas fa-gas-pump"
        m2Title="Fuel"
        m2Description={moduleDes2}
        m3Icon="fa-bar-chart"
        m3Title="Management"
        m3Description={moduleDes3}
        demoImage={demoImage}
      />
    </div>
  );
}
