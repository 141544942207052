import { Helmet } from "react-helmet";
import "../../css/contact.css";
import { useState, useEffect, useCallback } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import addressImage from "../../images/Ironbark-map.png";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const CAPTCHA_URL = process.env.REACT_APP_CAPTCHA_URL;
const CONTACT_URL = process.env.REACT_APP_CONTACT_URL;

export function Contact() {
  const industryOptions = [
    { value: 1, label: "Fresh Produce" },
    { value: 2, label: "Timber" },
    { value: 3, label: "Fuel Distribution" },
    { value: 4, label: "Third Party Logistics" },
    { value: 5, label: "Warehouse Management" },
    { value: 6, label: "Manufacturing" },
    { value: 7, label: "Retail" },
    { value: 8, label: "Project Management" },
  ];

  const featureOptions = [
    { value: 1, label: "Works everywhere" },
    { value: 2, label: "EDI Compatible" },
    { value: 3, label: "Customisable" },
    { value: 4, label: "Auditable" },
    { value: 5, label: "Multi Company" },
    { value: 6, label: "Complete Package" },
  ];

  const [frmDemo, setFrmDemo] = useState(false);
  const [frmFeature, setFrmFeature] = useState("");
  const [frmSelectFeature, setFrmSelectFeature] = useState([]);
  const [frmSelectIndustry, setFrmSelectIndustry] = useState([]);
  const [frmName, setFrmName] = useState("");
  const [frmEmail, setFrmEmail] = useState("");
  const [frmPhone, setFrmPhone] = useState("");
  const [frmCompany, setFrmCompany] = useState("");
  const [frmPosition, setFrmPosition] = useState("");
  const [frmWebsite, setFrmWebsite] = useState("");
  const [frmIndustry, setFrmIndustry] = useState("");
  const [frmComment, setFrmComment] = useState("");

  const [token, setToken] = useState("");
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleIndustryChange = (e) => {
    setFrmIndustry(e.map((u) => u.label).join(", "));
    setFrmSelectIndustry(e);
  };

  const handleFeatureChange = (e) => {
    setFrmFeature(e.map((u) => u.label).join(", "));
    setFrmSelectFeature(e);
  };

  const handleDemoChange = (e) => {
    setFrmDemo(e.target.checked);
  };

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    let captchaToken = await executeRecaptcha("create_comment");
    setToken(captchaToken);
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const OnSubmit = async function (event) {
    event.preventDefault();
    // let lines = [
    //   `name: ${frmName.target?.value ?? ""}`,
    //   `email: ${frmEmail.target?.value ?? ""}`,
    //   `phone: ${frmPhone.target?.value ?? ""}`,
    //   `company: ${frmCompany.target?.value ?? ""}`,
    //   `position: ${frmPosition.target?.value ?? ""}`,
    //   `website: ${frmWebsite.target?.value ?? ""}`,
    //   `industry: ${frmIndustry ?? ""}`,
    //   `feature: ${frmFeature ?? ""}`,
    //   `comment: ${frmComment.target?.value ?? ""}`,
    //   `demo: ${frmDemo ?? false}`,
    // ];
    // let msg2 = lines.join(";");
    let msg = JSON.stringify({
      name: frmName ?? "",
      email: frmEmail ?? "",
      phone: frmPhone ?? "",
      company: frmCompany ?? "",
      position: frmPosition ?? "",
      website: frmWebsite ?? "",
      industry: frmIndustry ?? "",
      feature: frmFeature ?? "",
      comment: frmComment ?? "",
      demo: frmDemo ?? false,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ message: msg }),
    };
    await fetch(`${CONTACT_URL}`, requestOptions)
      .then((response) => response.json())
      .then((responseJson) => {
        //console.log(responseJson);
        alert("Our friendly staff will contact you soon!");
        resetForm();
      })
      .catch((error) => {
        alert(error);
      });
  };
  const resetForm = () => {
    setFrmDemo(false);
    setFrmName("");
    setFrmEmail("");
    setFrmFeature("");
    setFrmPhone("");
    setFrmCompany("");
    setFrmPosition("");
    setFrmWebsite("");
    setFrmIndustry("");
    setFrmComment("");
    setFrmSelectFeature([]);
    setFrmSelectIndustry([]);
  };

  return (
    <div className="contact-page">
      <Helmet>
        <meta name="description" content="Ironbark Software Contact" />
        <meta name="robots" content="index,follow" />
        <meta
          name="keywords"
          content="Ironbark, Ironbark Industries, Fresh Produce, Fuel Distribution, Wholesale Distribution, ERP, Enterprise Resource Planning Software, Supply Chain, WMS, Warehouse Management System, Warehouse Management Software"
        />
      </Helmet>
      <div className="page-container">
        <div className="contact-info">
          <h1 className="ironbark-green">Ironbark Software</h1>
          <span>
            <i className="fa fa-building" /> Level 1, 29 Brandl Street, Brisbane
            Technology Park, Eight Mile Plains, Qld
          </span>
          <span>
            <i className="fa fa-map-marker" /> -27.581037,153.098753
          </span>
          <span>
            <div
              className="ironbark-address-img"
              style={{ backgroundImage: `url(${addressImage})` }}
            ></div>
          </span>
          <span>
            <i className="fa fa-phone" />{" "}
            <a href="tel:1800649524">1800 649 524</a>
          </span>
          <span>
            <i className="fa fa-phone" />
            <a href="tel:+61734233811">+61 7 3423 3811</a>
          </span>
          <span>
            <i className="fa fa-envelope" />
            <a href="mailto:sales@ironbark.com.au">sales@ironbark.com.au</a>
          </span>
        </div>
        <div className="contact-form">
          <h1>
            <i className="fa fa-envelope" />
            {"  "}Contact Form
          </h1>
          <form onSubmit={OnSubmit}>
            <fieldset>
              <label htmlFor="name">Name</label>
              <input
                id="name"
                required
                value={frmName}
                placeholder="First and Last Name"
                type="text"
                onChange={(e) => setFrmName(e.target.value)}
              ></input>
              <label htmlFor="email">Email</label>
              <input
                id="email"
                required
                value={frmEmail}
                placeholder="Email Address"
                type="email"
                onChange={(e) => setFrmEmail(e.target.value)}
              ></input>
              <label htmlFor="phone">Phone</label>
              <input
                id="phone"
                required
                value={frmPhone}
                placeholder="Phone No"
                type="tel"
                onChange={(e) => setFrmPhone(e.target.value)}
              ></input>
              <label htmlFor="company">Company</label>
              <input
                id="company"
                value={frmCompany}
                placeholder="Company Name"
                type="text"
                onChange={(e) => setFrmCompany(e.target.value)}
              ></input>
              <label htmlFor="position">Position</label>
              <input
                id="position"
                value={frmPosition}
                placeholder="Position"
                type="text"
                onChange={(e) => setFrmPosition(e.target.value)}
              ></input>
              <label htmlFor="website">Website</label>
              <input
                id="website"
                value={frmWebsite}
                placeholder="Website Url"
                type="text"
                onChange={(e) => setFrmWebsite(e.target.value)}
              ></input>
              <label>Industry</label>
              <Select
                value={frmSelectIndustry}
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={industryOptions}
                onChange={handleIndustryChange}
              />
              <label>Feature</label>
              <Select
                value={frmSelectFeature}
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={featureOptions}
                onChange={handleFeatureChange}
              />
              <label htmlFor="comment">Comment / Question</label>
              <textarea
                id="comment"
                value={frmComment}
                placeholder="Type Comment"
                resize="none"
                rows="5"
                type="text"
                onChange={(e) => setFrmComment(e.target.value)}
              ></textarea>
              <div class="checkbox-container">
                <input
                  type="checkbox"
                  id="requestDemo"
                  class="checkbox-checkmark"
                  name="requestDemo"
                  checked={frmDemo}
                  onChange={handleDemoChange}
                />
                <label for="requestDemo" class="checkbox-label">
                  Request a demo
                </label>
              </div>
              <button className="submit-button" type="submit">
                SEND
                <i className="fa fa-chevron-circle-right" />
              </button>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
}
