import { Helmet } from "react-helmet";
import bannerimg from "../../../images/banners/retail.jpg";
import benefitsImage from "../../../images/retail3.jpg";
import demoImage from "../../../images/iStock-467639856.jpg";
import { IndustryPage } from "./industryPage";

// Detailed instructions for updating industry pages can be viewed in industryPage.js
export function Retail() {
  const bannerText =
    "Manage your inventory, regulate your pricing and increase your customer engagement.  Ironbark will take you from the point of sale " +
    "to the warehouse and financials with our complete ERP Solution.";
  const benefits = [
    "Create and Print your own barcodes",
    "Manage your own Promotional Pricing",
    "Track companies employees and what their entitlements for purchasing are.",
    "Report on customers employees and what they are purchasing",
    "Use Pdas and tablets to do sales, stocktakes and look up stock",
    "Setup multiple locations and assign users to locations",
    "Zip pay and after pay options",
    "Use our CRM to engage clients",
    "Offer customers outstanding invoice reports and options to pay on the website.  ",
    "Secure payment gateways",
    "Date Driven Special Pricing",
    "Authorisation Processes",
  ];
  const moduleDes1 = [
    "Multiple deposits",
    "EFTPOS integration",
    "Gift Card integration",
    "Receipt money",
    "Barcode scanning",
    "Track user transactions",
    "Till balancing",
    "Create debtors accounts",
    "Customer confirmations",
    "Backorders",
    "Create credits",
    "WMS integration",
  ];
  const moduleDes2 = [
    "Turn quotes to orders and purchase orders",
    "Stock auto reordering",
    "Labour tracking",
    "User defined labels",
    "Barcode scanning and creation",
    "Pick queues and RF scanning",
    "PDA stocktakes and sales",
    "Location and customer based price lists",
    "Integrated web store",
    "Centralised purchasing",
  ];
  const moduleDes3 = [
    "BI reporting",
    "Full financials",
    "Complete payroll system",
    "Quotes",
    "EDI integrations",
    "Sales",
    "Purchasing",
    "Job costings",
    "Assets",
  ];
  return (
    <div>
      <Helmet>
        <meta name="description" content="Retail Systems" />
        <meta name="robots" content="index,follow" />
        <meta
          name="keywords"
          content="Retail, barcode scanning, eftpos, Point of Sale,WMS, Warehouse Management System, Warehouse Management Software, RF scanning. gift cards, zippay, afterpay, EDI, Ironbark, Ironbark Software"
        />
      </Helmet>
      <IndustryPage
        bannerImage={bannerimg}
        pageHeader="Retail"
        moduleHeader="Retail"
        benefits={benefits}
        benefitsImage={benefitsImage}
        bannerText={bannerText}
        m1Icon="fa-cash-register"
        m1Title="Point of Sale"
        m1Description={moduleDes1}
        m2Icon="fa-cash-register"
        m2Title="Retail"
        m2Description={moduleDes2}
        m3Icon="fa-store"
        m3Title="Other Modules"
        m3Description={moduleDes3}
        demoImage={demoImage}
      />
    </div>
  );
}
