import { Helmet } from "react-helmet";
import { Banner } from "../util/banner";
import bannerImage from "../../images/banners/features.jpg";
//import { Solution } from "../util/solution";
import "../../css/features.css";
import farmImage from "../../images/farm.jpg";
import timesheetImage from "../../images/payroll.jpg";
import schedulingImage from "../../images/scheduling.jpg";
import { IBButton } from "../util/ibButton";

// import austechImage from "../../images/austech.jpg"  //Use this and replace the file name if adding an austech project tile
// import payrollImage from "../../images/payroll.jpg"  //Use this and replace the file name if adding an payroll stp project tile

//  Features in this context refer to the tiles in the large, main section of the features page
//  Each feature should include:
//    icon        - This is the icon that appears to the left of the feature tile
//                  *Note: If using font awesome icons make sure to include fa at the start like "fa fa-map-marker"
//    title       - The bold text at the top right of the feature tile
//    description - The grey text that appears at the bottom right of the feature tile
const features = [
  {
    icon: "fa fa-map-marker",
    title: "Works everywhere",
    description: "Work from anywhere at anytime.",
  },
  {
    icon: "fa fa-clock-o",
    title: "EDI Compatible",
    description: "Edi solutions for sales and purchasing.",
  },
  {
    icon: "fa fa-list-alt",
    title: "Customisable",
    description: "Get software that works how you want.",
  },
  {
    icon: "fa fa-leaf",
    title: "Auditable",
    description: "Get full audit trails on what your staff are up to.",
  },
  {
    icon: "fa fa-plane",
    title: "Multi Company",
    description: "Run multiple companies under one solution.",
  },
  {
    icon: "fa fa-phone",
    title: "Complete Package",
    description: "Have one integrated system for your business.",
  },
];

//  Projects are the tiles that appear in the Development section of the features page
//  Each project should include
//    img         - This is the circlular image at the top of the tile. It should be imported at the top of this page before being used
//    title       - This is the large, bold text on the project tile
//    subtitle    - This is the smaller text that appears between the title and description, used to categorise the project
//    description - This the text box at the bottom of the tile which is used to describe the project
const projects = [
  {
    img: farmImage,
    title: "Farm Management",
    subtitle: "WEB APP",
    description:
      "Block and paddock management such as spray diaries, chemical management and yields with integration back into the ERP solution.",
  },
  {
    img: timesheetImage,
    title: "Timesheets",
    subtitle: "WEB APP",
    description:
      "Log your work time online from anywhere. Integration is back into the main ERP payroll system.",
  },
  {
    img: schedulingImage,
    title: "Scheduling",
    subtitle: "WEB APP",
    description:
      "Neet to schedule a process in your system. Our new scheduler can be used for multiple purposes throughout the system.",
  },
];
export function Features() {
  return (
    <div>
      <Helmet>
        <meta name="description" content="Ironbark Features" />
        <meta name="robots" content="index,follow" />
        <meta
          name="keywords"
          content="freatures, Ironbark Software, Ironbark, ERP, Enterprise Resource Planning Software, EDI, Farm Management, Web Timesheets, Production Scheduling"
        />
      </Helmet>
      <Banner img={bannerImage} /*title="Features"*/ />
      <div className="features-container">
        <h1 className="page-header">Features</h1>
        <div className="intro feature-description">
          <p>Let us help you gain control of your systems</p>
        </div>
        <div className="page-content">
          {features.map((f) => (
            <div key={f.title} className="feature">
              <i className={"feature-icon " + f.icon} aria-hidden="true"></i>
              <div className="feature-label">
                <h3>{f.title}</h3>
                <p className="feature-description">{f.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="development-container">
        <div className="intro">
          <h1>Innovation</h1>
          <p>Ironbark are always developing and improving our solutions.</p>
          <p>Some of our current projects and completed works include:</p>
        </div>
        <div className="page-content">
          {projects.map((p, index) => (
            <div key={index} className="project">
              <img alt={p.title} title={p.title} src={p.img} />
              <h1>{p.title}</h1>
              <p>{p.subtitle}</p>
              <p>{p.description}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="page-content">
        <p>
          Do you have a business problem you need a solution to? Get in touch
          and our team will explore potential solutions for your needs.
        </p>
        <IBButton label="Enquire Here" link="/contact" />
      </div>
    </div>
  );
}
