import { Helmet } from "react-helmet";
import bannerimg from "../../../images/banners/freshproduce.jpg";
import benefitsImage from "../../../images/iStock-1128687123.jpg";
import demoImage from "../../../images/istockphoto-589415708-612x612.jpg";
import eFreshPakImage from "../../../images/eFreshPak_print.jpg";
import { IndustryPage } from "./industryPage";

// Detailed instructions for updating industry pages can be viewed in industryPage.js
export function FreshProduce() {
  const bannerText =
    "Our experience in fresh food extends from production (growers and packhouse operations) through distribution (market agents, " +
    "wholesalers, providores, and exporters) and onto retail.  Our clients handle fruit, vegetables, nuts, seafood, flowers and much " +
    "more. We are uniquely placed to deliver systems which address the special needs of the fresh produce industry.";
  const benefits = [
    "We provide a comprehensive web based QA system with user defined templates",
    "Full chain store EDI integration with SSCC label generation",
    "Integration with hardware such as graders / sizers",
    "Freight reconciliations with freight rate tables",
    "Complete traceability back to the growers payback",
    "Live stock control with unlimited amount of products",
    "Complete financial and payroll integration",
    "Buy and sell in different units with multiple unit conversions per product.  ",
    "Fully integrated online webshop ",
    "Setup customer based pricing structures ",
    "Grower estimates ",
    "Sales Anaysis and BI reporting",
  ];
  const moduleDes1 = [
    "Bin receipts",
    "Harvest entries",
    "Customisable labels",
    "Packouts",
    "Pooling and non-pooling grower paybacks",
    "Live reports and trends",
    "Pallet allocations",
    "Value add and repacking",
    "Order and dispatch",
    "Labour capture",
    "Block to customer tracking",
  ];
  const moduleDes2 = [
    "Real time inventory management",
    "Multi location / multi BAS",
    "Full financials and payroll",
    "Warehouse managment",
    "Stock transfers",
    "BI reporting",
    "Traceability back to receival of stock",
    "Activity costings and charges",
    "Third party storage tracking",
    "Crate and pallet tracking",
    "Production scheduling",
  ];
  const moduleDes3 = [
    "Sales website",
    "Customer portals",
    "Store orders / chain store EDI",
    "Imports / exports",
    "Sales floor POS",
    "Rebates / levies / royalties",
    "SSCC / best before / packed on labels",
    "Direct to store",
    "Delivery scheduling",
    "Shipping charges",
    "QA tracking",
  ];
  //This section is used to render the eFreshPak/custom content section of the page
  const eFreshPak = (
    <div className="page-content">
      <img alt="eFreshPak" src={eFreshPakImage} />
      <div className="custom-content-text">
        <p>
          Designed for the small to medium pack houses that require
          functionality and smooth integration between the capture of pack out
          data, pallet control and marketing.
        </p>
        <p>
          The system is comprised of a local installation of label production
          (carton & pallets), RF wireless scanning, stock control and dispatch
          manifests.
        </p>
        <p>The labels include carton, pallet and SSCC labels.</p>
        <p>
          This is seamlessly integrated with an internet-hosted ‘cloud’
          application providing the pack house with functions for Sales Orders &
          Consignments, Market Returns, Grower RCTI and averaging of market
          returns.
        </p>
        <p>
          This hosted application provides administrative functions such as
          Backups and Extensive reporting.
        </p>
        <p>
          Freight forward advices and emails are handled automatically by the
          web host.
        </p>
      </div>
    </div>
  );
  return (
    <div>
      <Helmet>
        <meta name="robots" content="index,follow" />
        <meta name="description" content="Fresh Produce Packhouse" />
        <meta
          name="keywords"
          content="Fresh Produce, Packhouse, Distribution, Manufacturing, Packing, SSCC, EDI, Pooling, Harvest, WMS , Warehouse  Management System, Web shop, Web Store, Quality Assurance, QA, Sales Floor, Growers , Growers Payback, chain Store, third party service, ERP, Enterprise Resource Planning Software, Supply Chain, WMS, Warehouse Management System, Warehouse Management Software"
        />
      </Helmet>
      <IndustryPage
        bannerImage={bannerimg}
        pageHeader="Fresh Produce"
        moduleHeader="Fresh Produce"
        bannerText={bannerText}
        benefits={benefits}
        benefitsImage={benefitsImage}
        m1Icon="fa-cube"
        m1Title="Packing Sheds"
        m1Description={moduleDes1}
        m2Icon="fa-barcode"
        m2Title="Warehouse Management"
        m2Description={moduleDes2}
        m3Icon="fas fa-truck"
        m3Title="Distribution"
        m3Description={moduleDes3}
        demoImage={demoImage}
        element={eFreshPak}
      />
    </div>
  );
}
