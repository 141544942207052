import { Helmet } from "react-helmet";
import bannerimg from "../../../images/banners/manufacturing.jpg";
import benefitsImage from "../../../images/manufacturing1.jpg";
import demoImage from "../../../images/ManufactureNew2.jpg";
import { IndustryPage } from "./industryPage";

// Detailed instructions for updating industry pages can be viewed in industryPage.js
export function Manufacturing() {
  const bannerText =
    "Optimise your production planning , scheduling and works order load.";
  const benefits = [
    "Accurate planning and pricing options",
    "Versatile cost methods",
    "Authorisation purchasing work flow",
    "Quote, plan and execute production with accuracy",
    "Track machine down times and planned outages",
    "Auto reordering  of raw materials",
    "Schedule production from anywherre",
    "Label production",
    "Production and BI Reporting",
    "Labour timesheets",
    "Forecast material requirements",
    "Production for stock or existing jobs",
  ];
  const moduleDes1 = [
    "Production reporting",
    "Machine setup",
    "Material requirement reporting",
    "Labour capture",
    "Drop and drag scheduling",
    "Machine outage tracking",
    "Works order routings",
    "Capacity planning",
  ];
  const moduleDes2 = [
    "Bill of materials with substitutions",
    "Works orders",
    "Labour tracking",
    "User defined labels",
    "Barcode scanning and creation",
    "Production and resource planning",
    "Process and bom based productions",
    "Quality control",
    "Automated emailing",
    "Equipment registers",
    "Actual and variance reporting",
  ];
  const moduleDes3 = [
    "BI reporting",
    "Full financials",
    "Complete payroll system",
    "QA systems",
    "EDI integrations",
    "Sales",
    "Purchasing",
    "Job costings",
    "Assets",
  ];
  return (
    <div>
      <Helmet>
        <meta name="description" content="Manufacturing Production" />
        <meta name="robots" content="index,follow" />
        <meta
          name="keywords"
          content="Manufacture, works orders, production, scheduling , web scheduler, bill of materials, Ironbark, Ironbark Software, ERP , WMS, Warehouse Management System, Warehouse Management Software"
        />
      </Helmet>
      <IndustryPage
        bannerImage={bannerimg}
        pageHeader="Manufacturing"
        moduleHeader="Manufacturing"
        benefits={benefits}
        benefitsImage={benefitsImage}
        bannerText={bannerText}
        m1Icon="fa-list-alt"
        m1Title="Production Scheduler"
        m1Description={moduleDes1}
        m2Icon="fa-cogs"
        m2Title="Manufacturing"
        m2Description={moduleDes2}
        m3Icon="fa-laptop"
        m3Title="Other Modules"
        m3Description={moduleDes3}
        demoImage={demoImage}
      />
    </div>
  );
}
