import logo from "../../images/thumbnails/logo_no_ring.png";
import { Container, Button, Nav, Navbar, NavDropdown } from "react-bootstrap";
import "../../css/navbar.css";
import { Offcanvas } from "react-bootstrap";

//  This component represents the navigation bar that appears at the top of every page. Pay close attention to the structure of this page when making alterations
export function NavBar() {
  return (
    <div className="nav-container">
      <Container>
        <Navbar collapseOnSelect expand="lg">
          <Container fluid>
            <Navbar.Brand href="/" className="logo">
              <img src={logo} alt="ironbark logo" /> Ironbark Software
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Offcanvas scroll placement="start">
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Navigation</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="me-auto"></Nav>
                <Nav>
                  <Nav.Link href="/">Home</Nav.Link>
                  <NavDropdown title="Industries" id="basic-nav-dropdown">
                    <NavDropdown.Item href="/industries/freshproduce">
                      Fresh Produce
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/industries/timber">
                      Timber
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/industries/fueldistribution">
                      Fuel Distribution
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/industries/thirdpartylogistics">
                      Third Party Logistics
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/industries/warehousemanagement">
                      Warehouse Management
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/industries/manufacturing">
                      Manufacturing
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/industries/retail">
                      Retail
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/industries/projectmanagement">
                      Project Management
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="Services" id="basic-nav-dropdown">
                    <NavDropdown.Item href="/services#implementation">
                      Implementation
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/services#development">
                      Custom Development
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/services#consulting">
                      Consulting
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/services#webdesign">
                      Web Design / Mobile Apps
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/services#automation">
                      Automations
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/services#integration">
                      Third Party Integrations
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/services#reporting">
                      Business Intelligence Reporting
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/services#support">
                      Support
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/services#hosting">
                      Hosting Services
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="Features" id="basic-nav-dropdown">
                    <NavDropdown.Item href="/features">
                      Features
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/modules">Modules</NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="/contact">Contact</Nav.Link>
                </Nav>
                <Button
                  href="https://support.ironbark.com.au/engage/"
                  variant="success"
                  target="_none"
                  className="log-in"
                >
                  Login
                </Button>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </Container>
    </div>
  );
}
