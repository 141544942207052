import { Carousel } from "react-bootstrap";
import { useState } from "react";
import "../../css/testimonials.css";
import carouselPrev from "../../images/thumbnails/carousel_prev.png";
import carouselNext from "../../images/thumbnails/carousel_next.png";

export function Testimonials() {
  const [activeIndex, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const testimonials = [
    {
      name: "Kevin Davis, Managing Director, Austec Industries",
      caption:
        "Since installing Ironbark back in 1993, we have been impressed with the functionality of the system and Ironbark’s commitment to the integration of new technologies. The conversion to C# has opened up lots of new possibilities for new technology integration and we’re looking forward to exploring these options with Ironbark Software in the future.",
      img: "",
    },
    {
      name: "David Hassett, Financial Controller, Clarence River Fisherman’s Co-Operative.",
      caption:
        "Ironbark Seafood has provided us with a flexible system that is an enormous improvement on our prior systems. They ensured a thorough understanding of our business needs and growth plans right from the outset and have used that knowledge to design the best system for us. With complete integration across the business, information now flows smoothly and allows us to run all of our operations more effectively.",
      img: "",
    },
    {
      name: "Shane Clarke, Managing Director, Favco Queensland",
      caption:
        "We were looking for a long-term management solution and with Ironbark Fresh Produce, we have a system that is capable of growing with us.",
      img: "",
    },
    {
      name: "Max Roberts, Managing Director, Totally Workwear Brendale, Brisbane, Bundaberg, Chermside, Gladstone, Ipswich, Paget, &  Wacol",
      caption:
        "Ironbark is a key strategic partner of Ojema Group. As a franchisee within the Totally Workwear Group, Ojema Group relies upon Ironbark as a mission critical, end to end ERP system providing Sales Order Management, Point of Sale, Purchasing and Inventory Management, Debtor and Creditor Management and Financials. Leveraging the capabilities of Ironbark, we have streamlined and automated our business and enabled significant revenue growth without significant wage growth due to efficiencies in our processes. The Ironbark team are committed to helping us eliminate issues and quickly resolving the inevitable glitch with 24x7 support. Since moving to Ironbark’s hosted solution nearly three years ago, our downtime would be less than 5 minutes in total, a critical success factor for a mission critical system. We look forward to continuing our partnership with Ironbark and further improving the usability of the solution and automation of administrative processes so that our people can increasingly focus on serving our customers.",
      img: "",
    },
    {
      name: "Hans Schwinn, Financial Controller / Systems Manager , Crokers Fuel & Oils.",
      caption:
        "The ease of use demonstrated on the prototype, the opportunity to move to a single business systems supplier, the ability to tailor the package to our requirements, and the price…",
      img: "",
    },
  ];
  return (
    <Carousel
      nextIcon={
        <span
          aria-hidden="true"
          style={{ backgroundImage: carouselNext }}
          className="carousel-control-next-icon"
        />
      }
      prevIcon={
        <span
          aria-hidden="true"
          style={{ backgroundImage: carouselPrev }}
          className="carousel-control-prev-icon"
        />
      }
      activeIndex={activeIndex}
      onSelect={handleSelect}
    >
      {testimonials.map((t, index) => (
        <Carousel.Item key={t.name + index}>
          {/* <img src={t.img} /> */}
          <h6>{t.caption}</h6>
          <Carousel.Caption>
            <p>- {t.name}</p>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}
