import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import "../../css/solution.css";
import { Collapse } from "react-bootstrap";

export function Solution(props) {
  const icon = "ironbark-green solution-icon fa " + props.icon;
  const link =
    props.link === undefined || props.link.length === 0 ? "" : props.link;
  const linkStlye =
    link == "" || link == undefined ? {} : { cursor: "pointer" };
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [expandedLocked, setExpandedLocked] = useState(false);
  const [hoverReadMore, setHoverReadMore] = useState(false);

  useEffect(() => {
    let ex = checkExpandedLocked();
    setExpandedLocked(ex);
    if (!expanded && ex === true) setExpanded(ex);
  }, [window.innerWidth]);

  function OnEntered() {
    if (props.static === undefined) setActiveLink("#");
  }
  function onExited() {
    if (props.static === undefined) setActiveLink(link);
  }

  function onSelectSolution(e) {
    if (props.static === undefined) {
      if (!expandedLocked && (!expanded || hoverReadMore === true)) {
        setExpanded(!expanded);
      } else if (expanded && props.link !== undefined) {
        navigate(link);
      }
    } else if (props.link !== undefined) {
      window.location.href = link;
    } else {
      e.preventDefault();
    }
  }

  function checkExpandedLocked() {
    if (window.innerWidth < 992) return false;
    return true;
  }

  return (
    <div
      className={"solution " + (expanded && !expandedLocked ? "full" : "")}
      style={linkStlye}
      onClick={onSelectSolution}
    >
      <div>
        <i className={icon} aria-hidden="true"></i>
        <h3 className="solution-header">{props.title}</h3>
      </div>
      <Collapse in={!expanded && !expandedLocked && props.static === undefined}>
        <p
          className={
            "solution-read-more " + (props.static !== undefined ? "hidden" : "")
          }
        >
          Read More
        </p>
      </Collapse>
      <Collapse
        in={expanded || expandedLocked || props.static !== undefined}
        // onEntered={OnEntered}
        // onExited={onExited}
      >
        {Array.isArray(props.description) ? (
          <ul className="solution-description">
            {props.description.map((d, index) => (
              <li key={index} className="solution-list-item">
                {d}
              </li>
            ))}
            {props.readMore === true ? (
              <Link
                onMouseEnter={() => setHoverReadMore(true)}
                onMouseLeave={() => setHoverReadMore(false)}
                className={
                  "solution-read-more " +
                  +(props.static !== undefined || expandedLocked
                    ? "hidden"
                    : "")
                }
                to=""
              >
                Collapse
              </Link>
            ) : (
              ""
            )}
          </ul>
        ) : (
          <div>
            <p className="solution-description">{props.description}</p>
            <Link
              onMouseEnter={() => setHoverReadMore(true)}
              onMouseLeave={() => setHoverReadMore(false)}
              className={
                "solution-read-more " +
                (props.static !== undefined || expandedLocked ? "hidden" : "")
              }
              to=""
            >
              Collapse
            </Link>
          </div>
        )}
      </Collapse>
    </div>
  );
}
