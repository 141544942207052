export function Banner(props) {
  return (
    <div
      className={`banner-container ${
        props.className == undefined ? "" : props.className
      }`}
    >
      <h1 className={props.className}>{props.title}</h1>
      <h2 className={props.className}>{props.subtitle}</h2>
      <div
        className="banner-image"
        style={{ backgroundImage: `url(${props.img})` }}
      ></div>
    </div>
  );
}
