import { Helmet } from "react-helmet";
import { Solution } from "../util/solution";
import { Banner } from "../util/banner";
import { Portfolio } from "../util/portfolio";
import { IBButton } from "../util/ibButton";
import { Testimonials } from "./testimonials";
import bannerImage from "../../images/banners/home.jpg";
import "../../css/home.css";
import "../../css/testimonials.css";
import ecommerceImg from "../../images/thumbnails/e-commerce.jpg";
import integrationsImg from "../../images/thumbnails/intergrations.jpg";
import farmfieldImg from "../../images/fullsize/Farmfield.jpg";
import truckImg from "../../images/thumbnails/Truck.jpg";
import plantingImg from "../../images/fullsize/planting.jpg";
import newsImg from "../../images/thumbnails/News.jpg";
//  Each solution should have a:
//    link          - This is a relative link for the corresponding page e.g. /industries/freshproduce
//    icon          - This determines the icon that is displayed for the solution
//    title         - This is the large title text for the solution
//    description   - This is the smaller, grey text that summarises the solution
//                      *Note: description is hidden on mobile layouts
const solutions = [
  {
    link: "/industries/freshproduce",
    icon: "fa-apple-alt",
    title: "Fresh Produce",
    description:
      "From the Farm to the Packhouse, Production, and Distribution, Ironbark can cater to all your needs.",
  },
  {
    link: "/industries/timber",
    icon: "fa-tree",
    title: "Timber",
    description:
      "Logging, Milling, Fabrication, and Wholesale through to Retail and Point of Sale",
  },
  {
    link: "/industries/fueldistribution",
    icon: "fa-truck",
    title: "Fuel Distribution",
    description:
      "Manage head office transactions, truck computers, complex pricing and more.",
  },
  {
    link: "/industries/thirdpartylogistics",
    icon: "fa-cube",
    title: "Third Party Logistics",
    description:
      "Streamlined control of receival, storage, picking and dispatch process from warehouse to distribution",
  },
  {
    link: "/industries/warehousemanagement",
    icon: "fa-box-open",
    title: "Warehouse Management",
    description:
      "Track product from the moment it enters your warehouse right through to the dispatch process",
  },
  {
    link: "/industries/manufacturing",
    icon: "fa-building",
    title: "Manufacturing",
    description:
      "Plan manufacturing processes from forecasting, production scheduling through to works orders and delivery",
  },
  {
    link: "/industries/retail",
    icon: "fa-shopping-basket",
    title: "Retail",
    description:
      "Complete in-depth inventory control. Total POS solution, multi store options, Web stores all the way to the back end financials.",
  },
  {
    link: "/industries/projectmanagement",
    icon: "fas fa-hard-hat",
    title: "Project Management",
    description:
      "Effectively manage your projects through each stage of quote, work in progress and invoicing.",
  },
];
//  Each Base Module should have a:
//    heading       - This is the bold heading that displays for each paragraph
//    description   - This is the text that displays under the bold heading
const baseModules = [
  {
    heading: "Financials",
    description: [
      "Multi BAS solution",
      "Asset Management",
      "Bank Reconciliations",
      "Reporting",
      "Single Touch Payroll",
      "Multi-currency financial package",
    ],
  },
  {
    heading: "Sales and Quoting",
    description: [
      "Create quotes through to sales orders",
      "Pick queues",
      "Export orders in multi currencies",
      "Produce labels, delivery schedules and truck sign on delivery solutions",
      "Send SMS alerts on scheduled deliveries",
    ],
  },
  {
    heading: "Inventory Management",
    description: [
      "Track all stock with real time stock level updates and multiple levels of traceability available",
      "Buy in one unit and sell in another with ease",
    ],
  },
  {
    heading: "Procurement",
    description: [
      "Scan in stock to receive",
      "Produce labels, shipping registers and on costing options",
      "Freight reconciliations",
      "Auto reordering",
      "Multiple suppliers per product with multiple costs",
    ],
  },
  {
    heading: "Warehouse Management",
    description: [
      "Scan to pick and pack",
      "Trace by bin locations and warehouse locations",
      "Gain insights into stock movements within your warehouse",
    ],
  },
  {
    heading: "Reporting",
    description: ["Produce insightful reports on all aspects of your business"],
  },
];
//  Each portfolio should have a:
//    text          - This is the text that appears on the portfolio tile on mouse over
//    link          - This is a relative link to the page
//    image         - This is the imported image that is displayed on the portfolio tile
//                      *Note:  Local images should first be imported at the top of this page before they are used
//                              here using the below format without the brackets
//                              import [imageName] from "../../images/[filename.jpg]";
const portfolio = [
  {
    text: "Web Hosting and Ecommerce",
    link: "/services",
    image: ecommerceImg,
  },
  { text: "Integrations", link: "/services", image: integrationsImg },
  {
    text: "Efresh Small Farm Packing",
    link: "/industries/freshproduce",
    image: farmfieldImg,
  },
  {
    text: "Delivery Management / Mobile Computing",
    link: "/industries/fueldistribution",
    image: truckImg,
  },
  { text: "Farm Management", link: "/features", image: plantingImg },
  { text: "News", link: "/news", image: newsImg },
];
export function Home() {
  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Ironbark Software  have developed comprehensive, integrated ERP software solutions for several specific industries"
        />
        <meta name="robots" content="index,follow" />
        <meta
          name="keywords"
          content="Ironbark, Ironbark Industries, Fresh Produce, Fuel Distribution, Wholesale Distribution, ERP, Enterprise Resource Planning Software, Supply Chain, WMS, Warehouse Management System, Warehouse Management Software, Retail, Job costing, third party services, Third party storage, Software solution, "
        />
      </Helmet>
      <div className="home-container">
        <Banner
          img={bannerImage}
          title="SOFTWARE TO GROW WITH"
          subtitle="Proudly Australian owned and operated, Ironbark Software are providers of fully integrated, world class business management solutions."
        />
        <div className="page-content">
          <h1 className="solution-container-header">Industry Solutions</h1>
          <div className="solution-container">
            {solutions.map((s, index) => (
              <Solution
                key={index}
                link={s.link}
                icon={s.icon}
                title={s.title}
                description={s.description}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="what-we-do-container">
        <div className="page-content">
          <h1>What we do</h1>
          <p className="divider">----</p>
          <strong className="">
            For over 40 years, Ironbark's primary focus has been the research
            and development of our ERP solutions, to consistently provide
            innovative services to medium and large sized growing businesses.
          </strong>
          <br />
          <strong>
            Ironbark develops industry specific business management software
            solutions for fresh produce, fuel, timber, supply chain,
            manufacturing, building & construction, project management,
            wholesale distribution and retail industries.
          </strong>
          <br />
          <br />
          <IBButton
            class="ironbark-green"
            link="/contact"
            label="FIND OUT MORE"
          />
        </div>
      </div>
      <div className="base-modules">
        <div className="page-content">
          <h1 className="ironbark-green">Ironbark Modules</h1>
          <div>
            {baseModules.map((m, index) => (
              <div className="module" key={index}>
                <span>
                  <i
                    aria-hidden="true"
                    className="fa fa-check ironbark-green"
                  />
                  <strong>{m.heading}</strong>
                </span>
                <ul>
                  {m.description.map((d, dIndex) => (
                    <li key={dIndex}>{d}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="portfolio-container">
        {portfolio.map((p, index) => (
          <Portfolio link={p.link} image={p.image} text={p.text} key={index} />
        ))}
      </div>
      <div className="testimonials">
        <div className="page-content">
          <h1 className="ironbark-green">Client Testimonials</h1>
          <Testimonials />
        </div>
      </div>
    </div>
  );
}
