import { Helmet } from "react-helmet";
import bannerimg from "../../../images/banners/3pl.jpg";
import benefitsImage from "../../../images/Forklift Driver in Warehouse.jpg";
import demoImage from "../../../images/worker-scanning-boxes-in-warehouse-picture-id175139561.jpg";
import { IndustryPage } from "./industryPage";

// Detailed instructions for updating industry pages can be viewed in industryPage.js
export function ThirdPartyLogistics() {
  const bannerText =
    "We provide a complete solution for operating a third party cold storage business.  " +
    "Gain insight to your inventory and storage capabilities with our smart easy to use package.";
  const benefits = [
    "A comprehensive web based warehouse management solution",
    "Partial picks and mixed pallets",
    "Integrate with Xero",
    "Track and trace various crates and pallets",
    "Empower clients to see their own stock holdings",
    "Live stock control and bin/ pallet movement tracking",
    "Driver digital POD on dispatch",
    "Label Designer - create multiple layouts",
    "Comprehensive charge options",
    "Customer based date driven charge structures",
    "Manage Stock Issues such as quarantines",
    "Sales anaysis and BI reporting",
    "Carton serial and batch tracking",
  ];
  const moduleDes1 = [
    "Customer portal with inventory reporting",
    "Inwards and outwards entries",
    "Restacking",
    "Picking and staging",
    "Pallet tracking",
    "Put away of stock",
    "Stock takes",
    "Reporting and  reprinting of previous invoices",
    "Quality assurance documents and reporting",
    "Quality assurance  templates",
  ];
  const moduleDes2 = [
    "Real time inventory management",
    "Temperature Zones",
    "Multiple locations",
    "SSCC labels",
    "Touch screen sign on glass dispatch",
    "Automated invoice, billing  and reporting creation",
    "Partial picks and mixed pallets",
    "EDI integration",
    "Automated emailing",
    "Picker allocations and pick queue",
    "Crate and pallet tracking",
  ];
  const moduleDes3 = [
    "BI reporting",
    "Full financials",
    "Complete payroll system",
    "Date driven industry standard charges",
    "Barcode scanning and creation",
    "Cross docking",
    "Stock and pallet reporting",
    "PDA options",
    "Multiple options for picks - FIFO, wave, expiry date",
    "Random stock takes",
  ];
  return (
    <div>
      <Helmet>
        <meta name="description" content="Third Party Software Cold Storage" />
        <meta
          name="keywords"
          content="Third Party, 3PL, Cold storage, Supply Chain, WMS, Warehouse Management System, Warehouse Management Software, RF Scanning, EDI, SSCC, Ironbark Software, Ironbark, ERP, Enterprise Resource Planning Software"
        />
        <meta name="robots" content="index,follow" />
      </Helmet>
      <IndustryPage
        bannerImage={bannerimg}
        pageHeader="Third Party Logistics"
        moduleHeader="Third Party"
        benefits={benefits}
        benefitsImage={benefitsImage}
        bannerText={bannerText}
        m1Icon="fa-shopping-cart"
        m1Title="Web Apps"
        m1Description={moduleDes1}
        m2Icon="fa-truck"
        m2Title="Third Party"
        m2Description={moduleDes2}
        m3Icon="fa-calendar"
        m3Title="Administration"
        m3Description={moduleDes3}
        demoImage={demoImage}
      />
    </div>
  );
}
